import './CompFiveFAQ.css';
import api from '../../api/axiosConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";


function CompFiveFAQ() { 

 const [cardActiv, setCardActiv] = useState('');

  const faqItems = [
       {
      id: '1',
      question: 'Ist Bluumer kostenlos?',
      answer: 'Die komplette Plattform ist vollkommen kostenlos. Wir bitten lediglich um euer Feedback und eure Anregungen, wie wir die Plattform weiter verbessern können. Eure Meinung ist uns wichtig!',
    },
    {
      "id": "2",
      "question": "Wie funktioniert Bluumer? Gibt es Regeln?",
      "answer": "Ja, Bluumer hat einige wichtige Regeln und Funktionen. Diese findest du im Forum unter 'Bluumer Themen', wo alle relevanten Informationen übersichtlich erklärt sind."
    },    
   /* {
      id: '2',
      question: 'Welche Vorteile bietet die Warteliste?',
      answer: 'Mit dem Eintrag auf die Warteliste wirst du über alle Neuigkeiten informiert, bist sofort startklar beim Launch, erhältst in Zukunft Vorteile und unterstützt die Plattform durch deine Antworten auf ein paar Fragen, um sie für dich zu verbessern und noch nützlicher zu machen.',
    },
    {
      id: '3',
      question: 'Wie setze ich mich auf die Warteliste?',
      answer: 'Trage einfach deine E-Mail-Adresse ein, drücke „Warteliste anmelden“ und beantworte ein paar Fragen zum Thema Buch-Bereich. Klicke anschließend „Warteliste beitreten“ und schon bleibst du informiert.',
    }, */
    {
      id: '4',
      question: 'Kann ich mehrere Profile erstellen?',
      answer: 'Du hast die Möglichkeit, ein Profil als Autor, Lektor & Korrektor, Testleser, Verlag oder Coverdesigner zu erstellen. Sobald du mehrere Rollen nutzen möchtest, erstelle einfach die zusätzlichen Profile in deinem Benutzerkonto.',
    },
    {
      id: '5',
      question: 'Wie setze ich mein Passwort zurück?',
      answer: 'Wenn du dein Passwort zurücksetzen musst, klicke auf den Link "Passwort vergessen" auf der Anmeldeseite. Folge den Anweisungen, um einen Link zum Zurücksetzen des Passworts per E-Mail zu erhalten.',
    },
    {
      id: '6',
      question: 'Wie aktualisiere ich meine Profilinformationen?',
      answer: 'Du kannst deine Profilinformationen aktualisieren, indem du dich in dein Konto einloggst und zum Abschnitt "Mein Profil" navigierst. Hier kannst du deine persönlichen Daten, Kontaktinformationen und Präferenzen bearbeiten.',
    },
    {
      id: '7',
      question: 'Sind meine persönlichen Daten sicher?',
      answer: 'Ja, wir nehmen deine Privatsphäre und Sicherheit ernst. Wir und unsere Drittanbieter verwenden fortschrittliche Verschlüsselungs- und Sicherheitsprotokolle, um deine persönlichen Daten zu schützen. Weitere Details findest du in unserer Datenschutzerklärung.',
    },
    {
      id: '8',
      question: 'Wie kann ich den Kundensupport kontaktieren?',
      answer: 'Du kannst unser Kundensupport-Team über die Seite "Kontakt" erreichen. Wir bieten Unterstützung per E-Mail an. Unser Team steht dir rund um die Uhr zur Verfügung.',
    },
    {
      id: '9',
      question: 'Wie melde ich ein Problem oder gebe Feedback?',
      answer: 'Wenn du auf Probleme stößt oder Feedback hast, nutze bitte die Email, welche du über die Seite "Kontakt" erreichst. Dein Input hilft uns, unsere Plattform zu verbessern.',
    },
  ];

  return (
    <div className="CompFiveFAQ text-center mt-10 sm:mt-80 max-sm:pt-80 max-sm:pb-80 max-sm:mb-80">
      <div className="text-center justify-center items-center pt-10 max-sm:pb-80">
        <h1 className="text-4xl font-bold mb-10">FAQ</h1>
        <div className="mx-auto w-full lg:w-2/3">
          {faqItems.map((item) => (
            <div key={item.id} className={`mb-3 ${cardActiv === item.id ? 'profileDataCard white-glassmorphism-faq' : 'mt-10 mb-10'}`}>
              <div className={`flex justify-between items-center py-3 landingCompFourColor text-white ${cardActiv === item.id ? 'rounded-t-lg' : 'rounded-full'}`}>
                <h3 className="mt-5 mb-5 pb-1 mx-10 max-sm:text-3xl text-4xl text-left">{item.question}</h3>
                <div className="mx-10" onClick={() => setCardActiv(cardActiv === item.id ? '' : item.id)}>
                  {cardActiv === item.id ? <FaMinus /> : <FaPlus />}
                </div>
              </div>
              {cardActiv === item.id && (
                <div>
                  <div className="mx-6"><hr className="horizontalLine" /></div>
                  <p className="p-3 text-3xl">{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CompFiveFAQ; 
export { default as CompFiveFAQ } from './CompFiveFAQ';