
import api from '../../api/axiosConfig.js';
import './Chatbox.css';

import { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { ImHappy } from "react-icons/im";
import { ImCross } from "react-icons/im";
import { ImSearch } from "react-icons/im";

import {TimestampFormatter} from '../../tools/TimestampFormatter.js';
import {TimestampFormatterLight} from '../../tools/TimestampFormatterLight.js';
import {ShortTimestampFormatter} from '../../tools/ShortTimestampFormatter.js';
import {TrimTalkName} from '../../tools/TrimTalkName.js';
import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';
import { SanitizeInput } from '../../tools/security/SanitizeInput';
import { sanitizeInputTextArea } from '../../tools/security/SanitizeInputTextArea';


  function shortenText(text, maxLength) {
	if (text.length <= maxLength) {
	  return text;
	} else {
	  return text.substring(0, maxLength) + '...';
	}
  }


const Chatbox = ({chatState, setChatState}) => {

	const [getSingleProfileData, setSingleProfileData] = useState();
	const [searchInputValue, setSearchInputValue, ] = useState([]);
	const [getTalksData, setTalksData, ] = useState([]);
	const [getShowSingleTalkMessages, setShowSingleTalkMessages] = useState();
	const [isClicked, setIsClicked] = useState(false);
	const [isProfileName, setIsProfileName] = useState();
	const [getIsReceiverName, setIsReceiverName] = useState();
	const [getPrevTimestamp, setPrevTimestamp] = useState(null);
	const [showEmoji, setShowEmoji] = useState(false);
	const [text, setText] = useState('');
	const [unreadMessages, setUnreadMessages] = useState({});

	const [messageBoxVisible, setMessageBoxVisible] = useState(true);
	const [getImgSrc, setImgSrc] = useState('');
	const [getImgSrcName, setImgSrcName] = useState('');

	const [getCoverDesignerEntityData, setCoverDesignerEntityData] = useState(null);
	const [getAuthorEntityData, setAuthorEntityData] = useState(null);
	const [getEditorEntityData, setEditorEntityData] = useState(null);
	const [getPublisherEntityData, setPublisherEntityData] = useState(null);
	const [getTestReaderEntityData, setTestReaderEntityData] = useState(null);

	const [getNotRightsToChat, setNotRightsToChat] = useState(false);

	const [getDashboardChatAlreadyCreated, setDashboardChatAlreadyCreated] = useState(false);
	//const [getChatEntityName, setChatEntityName] = useState('');

	

	const [error, setError] = useState(null);

	const chatboxRef = useRef();
	
///////////////////////////////// Logout redirect //////////////////////////7

const navigate = useNavigate();

useEffect(() => {
  LogoutRedirectCheck(navigate);
}, [showEmoji, isClicked, getTalksData, unreadMessages ]);

///////////////////////////////// Logout redirect //////////////////////////7


const doSetMessageBoxVisible = () => {
	setMessageBoxVisible(!messageBoxVisible);
}

const currentTalkwithName = () => {

setImgSrcName(TrimTalkName(getShowSingleTalkMessages))	
}


const getSingleProfileDashboard = async () => {
	setNotRightsToChat(false);

	getSingleProfileService(localStorage.getItem('chatEntityName'));
	//console.log('12345');	
	//console.log('localStorage.getItem(chatEntityName):', localStorage.getItem('chatEntityName'));

};

const getSingleProfile = async (e) => {
       e.preventDefault();
	setNotRightsToChat(false);
  
	const { target } = e;
	const chatEntityName = SanitizeInput(target.profileName.value);
	//console.log('target.profileName.value:', target.profileName.value);

	getSingleProfileService(chatEntityName);
}


const getSingleProfileService = async (chatEntityName) => {



    if(chatEntityName !== '' && chatEntityName !== null && !getDashboardChatAlreadyCreated){
		
		
	try {
	  const response = await api.get(`/api/userProfile/search/${chatEntityName}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
	  setSingleProfileData(response.data[0]);
  
	  const response2 = await api.get(`/api/userProfile/${localStorage.getItem('profileName')}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });

	  //console.log("searchTalkProfile: ", response.data);
	  //console.log("searchTalkProfile[0]: ", response.data[0]);

	  const singleProfile = response.data[0];
	  const myProfile = response2.data;
  
	  if (singleProfile.userProfileType !== 'Book') {
		if(localStorage.getItem(`currentProfileType`) !== 'Publisher'){
				if (singleProfile.userProfileType === 'Author' || singleProfile.userProfileType === 'Publisher') {

					if(singleProfile.userProfileType === 'Author'){

						const response = await api.get(`/api/authors`, {
							headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
						});
						
						if(response.data != null && response.data !== ''){
						
						setAuthorEntityData(response.data);
						const authorData = response.data;
					
						//console.log('1111: ', getAuthorEntityData);

						authorData.forEach(author => {
							if(author.authorName === singleProfile.userProfileName){
								if((author.searchForLector === true && myProfile.userProfileType === 'Editor') ||
								(author.searchForTestReader === true && myProfile.userProfileType === 'Testreader') ||
								(author.searchForCoverDesign === true && myProfile.userProfileType === 'CoverDesigner')){
									localStorage.setItem('talkNameTwo', singleProfile.userProfileName);
									createTalk();
									//console.log('6');
								}else{
									if (singleProfile.allRights[0].toChat.length > 0) {  
										let rightsMatchFound = false;
										singleProfile.allRights[0].toChat.forEach(chat => {
										if (myProfile._id === chat._id) {
											localStorage.setItem('talkNameTwo', singleProfile.userProfileName);
											createTalk();
											rightsMatchFound = true;
											//console.log('5');
										}
										});
							
										if (!rightsMatchFound) {
										setNotRightsToChat(true);
										localStorage.setItem('chatEntityName', '');
										}
									} else {
										setNotRightsToChat(true);
										localStorage.setItem('chatEntityName', '');
									}
								}

							}
						})
					}
					
					}else{
						if (singleProfile.allRights[0].toChat.length > 0) {  
							let rightsMatchFound = false;
							singleProfile.allRights[0].toChat.forEach(chat => {
							if (myProfile._id === chat._id) {
								localStorage.setItem('talkNameTwo', singleProfile.userProfileName);
								createTalk();
								rightsMatchFound = true;
								//console.log('4');
							}
							});
				
							if (!rightsMatchFound) {
							setNotRightsToChat(true);
							localStorage.setItem('chatEntityName', '');
							}
						} else {
							setNotRightsToChat(true);
							localStorage.setItem('chatEntityName', '');
						}
					}
					
				} else {
				localStorage.setItem('talkNameTwo', singleProfile.userProfileName);
				createTalk();
				//console.log('3');
				}
	   }else{
		localStorage.setItem('talkNameTwo', singleProfile.userProfileName);
		createTalk();
		//console.log('2');
	   }

	  } else {
		console.log('Wrong Profiletype');
	  }
  
	  setSearchInputValue('');
	} catch (error) {
	  handleError('Error fetching single profile:', error);
	}
     }else{

		if(getDashboardChatAlreadyCreated){
			//console.log('getDashboardChatAlreadyCreated: ', getDashboardChatAlreadyCreated);
		}
	 }
  };
  
  
  const handleSearchInputChange = async (e) => {
	const sanitizedValue = SanitizeInput(e.target.value);
	setSearchInputValue(sanitizedValue);
  };


  let isCreatingTalk = false;
  
  const createTalk = async () => {

	if (isCreatingTalk) return; // Exit if the function is already running
	isCreatingTalk = true;

	const newTalkData = {
	  talkNameOne: localStorage.getItem('profileName'),
	  talkNameTwo: localStorage.getItem('talkNameTwo'),
	};
  
	try {
	  const response = await api.get(`/api/talks/allTalks/${localStorage.getItem('profileName')}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
  
	  const isTalkExists = response.data.some(
		(talks) =>
		  talks.talkName === ':' + newTalkData.talkNameOne + ':' + newTalkData.talkNameTwo + ':' ||
		  talks.talkName === ':' + newTalkData.talkNameTwo + ':' + newTalkData.talkNameOne + ':'
	  );

	  //console.log("isTalksExist: " , isTalkExists);
  
	  if (!isTalkExists) {
		const response2 = await api.post('/api/talks/create', newTalkData, {
		  headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
		});

		//console.log("TalksCreated: " , response2.data);
  
		if (response2 == null || response2.isEmpty) {
		  console.log('Conversation could not be created');
		}
	  } else {
		setDashboardChatAlreadyCreated(true);
		//console.log('Conversation exists already');
	  }
  //console.log('1');
	  fetchData();
	} catch (error) {
	  handleError('Error creating talk:', error);
	
	} finally {
    isCreatingTalk = false; // Reset the flag
  }
  };
  
  const getTalks = async () => {
	const userProfileName = localStorage.getItem('profileName');
  
	try {
	  const response = await api.get(`/api/talks/allTalks/${userProfileName}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
	  setChatState(false);
	  return response.data;
	} catch (err) {
	  handleError('Error fetching talks:', err);
	}
  };
  


  const deleteTalk = async (talkName) => {
	try {
	  const response2 = await api.delete(`/api/talks/delete/${talkName}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
	  });
  
	  if (response2 == null || response2.isEmpty) {
		console.log("Conversation could not be deleted");
	  }
	  localStorage.setItem('chatEntityName', '');
	  fetchData();
	} catch (error) {
		handleError('Error deleting talk:', error);
	}
  };


	const showSingleTalkMessages = async (talkName) => {
		try {
		  const talkName2 = ":" + localStorage.getItem('profileName') + ":";
		  const trimmedString = talkName.replace(talkName2, '').replace(/:$/, '').replace(/^:/, '');
	  
		  setIsReceiverName(trimmedString);
		  setShowSingleTalkMessages(talkName);
		  setIsClicked(true);
		  setTimeout(() => {
			scrollToBottom();
		  }, 1);
		} catch (err) {
			handleError('Error showing single talk messages:', err);
		}
	  };
	



	const createMessage = async (e) => {
		e.preventDefault();
	  
		setShowEmoji(false);
	  
		if (getShowSingleTalkMessages.isEmpty || getShowSingleTalkMessages === null) {
		  setIsReceiverName(localStorage.getItem('talkNameTwo'));
		}
	  
		const newMessageData = {
		  senderName: localStorage.getItem('profileName'),
		  receiverName: getIsReceiverName,
		  messageText: text,
		};
	  
		try {
		  const response = await api.post('/api/message/create', newMessageData, {
			headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
		  });
	  
		  setTimeout(() => {
			scrollToBottom();
		  }, 100);
	  
		  if (response === null || response.isEmpty) {
			console.log("Message could not be created");
		  }

		  fetchData();
		  localStorage.setItem('chatEntityName', '');
		  setText('');
		} catch (error) {
			handleError('Error creating message:', error);
		}
	  };

	  const getLastMessageTime = (talk) => {
		try {
		  if (talk.messageIds.length > 0) {
			const lastMessageCreatedAtArray = talk.messageIds[talk.messageIds.length - 1]?.messageCreatedAt;
	  
			if (lastMessageCreatedAtArray && Array.isArray(lastMessageCreatedAtArray) && lastMessageCreatedAtArray.length === 7) {
			  const [year, month, day, hour, minute, second, millisecond] = lastMessageCreatedAtArray;
	  
			  if (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hour) && !isNaN(minute) && !isNaN(second) && !isNaN(millisecond)) {
				return [year, month, day, hour, minute, second, millisecond];
			  }
			}
		  } else {
			const talkCreatedAtArray = talk.talkCreatedAt;
	  
			if (Array.isArray(talkCreatedAtArray) && talkCreatedAtArray.length === 7) {
			  const [year, month, day, hour, minute, second, millisecond] = talkCreatedAtArray;
	  
			  if (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hour) && !isNaN(minute) && !isNaN(second) && !isNaN(millisecond)) {
				return [year, month, day, hour, minute, second, millisecond];
			  }
			}
		  }
	  
		  return undefined;
		} catch (error) {
		  handleError('Error getting last message time:', error);
		  return undefined;
		}
	  };
	  
	  const scrollToBottom = () => {
		try {
		  if (chatboxRef.current) {
			chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
		  }
		} catch (error) {
			handleError('Error scrolling to bottom:', error);
	}
	  };
	  
	  const sortedTalks = getTalksData.slice().sort((a, b) => {
		try {
		  const timeA = getLastMessageTime(a);
		  const timeB = getLastMessageTime(b);
	  
		  if (timeA === undefined || timeB === undefined) {
			return 0;
		  }
	  
		  return (
			timeB[0] - timeA[0] ||
			timeB[1] - timeA[1] ||
			timeB[2] - timeA[2] ||
			timeB[3] - timeA[3] ||
			timeB[4] - timeA[4] ||
			timeB[5] - timeA[5] ||
			timeB[6] - timeA[6]
		  );
		} catch (error) {
		 handleError('Error sorting talks:', error);
		 return [];
		}
	  });


const addEmoji = (e) => {
  try {
    const sym = e.unified.split('_');
    const codeArray = sym.map((el) => parseInt(el, 16));

    if (codeArray.some(isNaN)) {
      console.error('Invalid hexadecimal values in codeArray:', codeArray);
      return;
    }

    let emoji = String.fromCodePoint(...codeArray);
    setText(text + emoji);
  } catch (error) {
    handleError('Error adding emoji:', error);
}
};

const updateUnreadMessages = (talks) => {
  try {
    const updatedUnreadMessages = unreadMessages;

    talks.forEach((talk) => {
      const lastMessage = talk.messageIds[talk.messageIds.length - 1];
      if (lastMessage && lastMessage.receiverName === localStorage.getItem('profileName') && !lastMessage.beenSeen && lastMessage.beenSeen != null) {
        updatedUnreadMessages[talk.talkName] = true;
      }
    });

    setUnreadMessages(updatedUnreadMessages);
  } catch (error) {
	handleError('Error updating unread messages:', error);
  }
};

const markMessageAsSeen = async (talkName) => {
  try {
    const updatedUnreadMessages = unreadMessages;

    getTalksData.forEach((Talk) => {
      if (Talk.talkName === talkName) {
        Talk.messageIds.forEach(async (message) => {
          if (!message.beenSeen && message.beenSeen !== null && message.receiverName === localStorage.getItem('profileName')) {
            const newMessageSeenData = {
              beenSeen: true,
              receiverName: message.receiverName,
              senderName: message.senderName,
            };

            try {
              const response5 = await api.post('/api/message/update/seen', newMessageSeenData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
              });
              //console.log(response5);
            } catch (error) {
              console.error(error);
            }
          }
        });
      }
    });

    updatedUnreadMessages[talkName] = false;
    setUnreadMessages(updatedUnreadMessages);
  } catch (error) {
    handleError('Error marking message as seen:', error);
 }
};


const handleTalkClick = (talkName) => {
	try {
	  showSingleTalkMessages(talkName);
	  markMessageAsSeen(talkName);
	  doSetMessageBoxVisible();
	} catch (error) {
		handleError('Error handling talk click:', error);
}
  };


    
  const fetchData = async () => {
	try {
	  if (localStorage.getItem('logintoken') === 'Please Log in') {
		navigate('/');
	  } else {
		const Talks = await getTalks();
		setTalksData(Talks);
		updateUnreadMessages(Talks);
	  }
	} catch (error) {
	  handleError('Error fetching data:', error);
	}
  };

  const getAllEntityData = async () => {


	try {
		const response = await api.get(`/api/authors`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
		});

		setAuthorEntityData(response.data);
		//console.log('678: ', response.data);

		const response2 = await api.get(`/api/editors`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
		});

		setEditorEntityData(response2.data);

		const response3 = await api.get(`/api/publishers`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
		});

		setPublisherEntityData(response3.data);

		const response4 = await api.get(`/api/testReaders`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
		});

		setTestReaderEntityData(response4.data);

		const response5 = await api.get(`/api/coverDesigners`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` }
		});

		setCoverDesignerEntityData(response5.data);

		
	} catch (error) {
		handleError('Error fetching single entity:', error);
	}
}; 
  
  useEffect(() => {
	try {
	  if (getShowSingleTalkMessages === undefined && sortedTalks && sortedTalks.length > 0) {
		const firstTalkName = sortedTalks[sortedTalks.length - 1].talkName;
		setShowSingleTalkMessages(firstTalkName);
	  }
	} catch (error) {
	  handleError('Error setting showSingleTalkMessages:', error);
	}
  }, []);
  
  useEffect(() => {
	try {
	  fetchData();

	  const fetchInterval = setInterval(fetchData, 20 * 1000);
  
	  // Cleanup the timer when the component unmounts
	  return () => clearInterval(fetchInterval);
	} catch (error) {
	  handleError('Error setting up fetch interval:', error);
	}
  }, []);
  
  useEffect(() => {
	try {
	  getAllEntityData();
	  setIsProfileName(localStorage.getItem('profileName'));
	  setIsClicked(false);
	  setShowEmoji(false);
	  setPrevTimestamp('');
	  scrollToBottom();
	  setImgSrc(`http://localhost:8080/api/files/image/`);
	  
	} catch (error) {
	  handleError('Error setting up initial state:', error);
	}
  }, []);

  const hasDashboardRunRef = useRef(false);

  useEffect(() => {
	if (!hasDashboardRunRef.current) {
          getSingleProfileDashboard()
		  hasDashboardRunRef.current = true;
	}	  
  }, []);

  const handleError = (message, error) => {
	console.error(message, error);
	setError('An error occurred. Please try again.');
  };



  return(

<div className="chatbox w-full min-h-full py-20  z-50" style={{ minHeight: "400px" }}>
  <div className="container-sm chatCol h-full">
  {/*  {error && <div style={{ color: 'red' }}>{error}</div>}  */}
  {/* {getNotRightsToChat && (<p className='mb-8' style={{ color: 'red' }}>Du bist nicht berechtigt diesem Profil zu schreiben!</p>)}  */}
  {/* {getDashboardChatAlreadyCreated && (<p className='mb-8' style={{ color: 'red' }}>Das Gespräch existiert bereits!</p>)} */}
  

    <div className='md:grid grid-rows-1 grid-flow-col gap-4 justify-center'>
      <div className={`col borderOneNew white-glassmorphism-card rounded-xl ${!messageBoxVisible?'max-md:hidden':''}`} style={{ minHeight: 40 , maxHeight: 50 }}>
        <div className='flex'>
          <div className="col-sm-1 mt-1.5 md:mt-2.5" style={{ minWidth: 40 }}><h5 className="TextBold">SUCHE</h5></div>
          <div className="col-sm-11 w-full">
            <form onSubmit={getSingleProfile} className='flex justify-between'>
				<div className="col-sm-10 w-full">
					<input
						className="border w-[100%] mx-3 p-2 mt-1.5 md:mt-3.5"
						placeholder="Wem willst du schreiben.."
						name="profileName"
						
						type="text"
						value={searchInputValue}
						onChange={handleSearchInputChange}
					/>
					</div>
				<div className="col-sm-2">
					<button className="btn_One bg-gray-600 text-white ml-0 mt-2 md:mt-4  p-2 rounded-lg"><ImSearch /></button>
			  	</div>
            </form>
          </div>
        </div>
      </div>
      <div className={`rows-span-3 col-span-1 min-w-[280px] overflow-auto  rounded-xl ${!messageBoxVisible?'max-md:hidden':''}`} style={{ minHeight: '580px', maxHeight: '580px' }}>
      { ( getNotRightsToChat || getDashboardChatAlreadyCreated ) && (    
	  <div className='grid grid-cols-4 borderOneNew my-1 talkHover hover:text-white rounded-xl  bg-red-300 ' >
            <div className='col-span-4 '>
              <div className="relative p-4 ">
                <>
                  <div className='opacity-100 absolute p-1 top-4 left-2' >
                    {getNotRightsToChat && (
                      <>
                        <ImCross className='text-black-900 cursor-pointer ' onClick={() => setNotRightsToChat(false)} />
                      </>
                    )}
					{getDashboardChatAlreadyCreated && (
                      <>
                        <ImCross className='text-black-900  cursor-pointer ' onClick={() => setDashboardChatAlreadyCreated(false)} />
                      </>
                    )}
                  </div>
                </>
              </div>
			 {getNotRightsToChat && ( <h4 className="col-span-4 text-left ml-16 text-left TextBold">Du bist nicht berechtigt diesem Profil zu schreiben!</h4> )}
			 {getDashboardChatAlreadyCreated && ( <h4 className="col-span 4 ml-16 text-left text-left TextBold ">Das Gespräch existiert bereits!</h4> )}
           
		    </div>
			</div>
	  )}
		
		
		{sortedTalks.map((talk) => (
          <div key={talk.talkName} className={`max-sm:col-sm-12 sm:grid sm:grid-cols-4 borderOneNew my-1 talkHover rounded-xl white-glassmorphism-card ${isClicked && getShowSingleTalkMessages === talk.talkName ? 'clicked' : ''} ${unreadMessages[talk.talkName] ? 'unread' : ''}`} onClick={() => handleTalkClick(talk.talkName)}>
            <div className='sm:col-span-2 max-sm:row'>
              <div className="relative p-4 ">
                <>
                  <div className={`opacity-100 hover:opacity-100 absolute p-1 top-4 left-2 ${isClicked && getShowSingleTalkMessages === talk.talkName ? 'opacity-100' : ''}`} >
                    {talk.messageIds.length === 0 && (
                      <>
                        <ImCross className='text-black-900 cursor-pointer ' onClick={() => deleteTalk(talk.talkName)} />
                      </>
                    )}
                  </div>
                </>
              </div>
			{/*  {getAuthorEntityData && getAuthorEntityData.map((author, index) => {
					if (author && author.authorName === <TrimTalkName originalString={talk.talkName} />) {
						return (
						<div key={index} className="profileImg2 rounded-full" style={{ maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px' }}>
							<div>
								<p>${author.profileImg}</p>
							<img className="img-fluid profileImg3 rounded-full" src={`${getImgSrc}${author.profileImg}`} alt=""
								style={{ maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px' }} />
							</div>
						</div>
						);
					} else {
						return null; // Render nothing if the entity is null or the names don't match
					}
					})}
			  {[getAuthorEntityData, getCoverDesignerEntityData, getEditorEntityData, getPublisherEntityData, getTestReaderEntityData].map((entity, index) => {
					if (entity && entity.authorName === <TrimTalkName originalString={talk.talkName} />) {
						return (
						<div key={index} className="profileImg2 rounded-full" style={{ maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px' }}>
							<div>
								<p>${entity.profileImg}</p>
							<img className="img-fluid profileImg3 rounded-full" src={`${getImgSrc}${entity.profileImg}`} alt=""
								style={{ maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px' }} />
							</div>
						</div>
						);
					} else {
						return null; // Render nothing if the entity is null or the names don't match
					}
					})}
				*/}
              <h4 className="max-sm:col-sm-12 sm:text-left max-sm:text-center ml-4 TextBold mobileView LinkColor"><TrimTalkName originalString={talk.talkName} /></h4>
            </div>
            <div className="sm:col-span-2 mobileView hidden"><h6><ShortTimestampFormatter timestamp={talk.talkCreatedAt} /></h6></div>
            <div className="max-sm:row sm:col-span-2">
              <>
                <div className="sm:col-span-4">
                  {talk.messageIds.length > 0 && (
                    <>
                      <h5 className='mobileView2'>{shortenText(talk.messageIds[talk.messageIds.length - 1].messageText, 40)}</h5>
                      <h5 className='mobileView2'><ShortTimestampFormatter timestamp={talk.messageIds[talk.messageIds.length - 1].messageCreatedAt} /></h5>
                    </>
                  )}
                </div>
              </>
            </div>
          </div>
        ))}
      </div>

	
      <div className={`row-span-2 col-span-9 borderOneNew flex flex-col lg:min-w-[750px] max-w-[750px] white-glassmorphism-card rounded-xl ${messageBoxVisible?'max-md:hidden':''}`}>
        <div className="col-sm-1  md:hidden">
			<div className="justify-between flex">
				<button className="btn_One bg-gray-600 text-white my-4 p-2 rounded-lg" onClick={ () => doSetMessageBoxVisible()}>zurück</button>
				<h3>{getIsReceiverName}</h3>
					
			</div>
			<hr className='horizontalLine' />
		</div>
		<div className="min-h-32 max-h-[500px] overflow-auto" style={{ maxHeight: "600px", minHeight: "600px" }} ref={chatboxRef}>
		
		  <div className="h-full p-4 w-full">
            {getTalksData.map((talk) => (
              <div key={talk.talkName}>
                {getShowSingleTalkMessages === talk.talkName && (
                  <>
                    <div className=''>
                      {talk.messageIds.length > 0 && (
                        <div className=''>
                          {talk.messageIds.map((messages, index) => {
                            const prevMessageTimestamp = index > 0 ? talk.messageIds[index - 1].messageCreatedAt : null;
                            return (
                              <>
                                {isProfileName === messages.senderName && (
                                  <>
                                    <div className='max-sm:text-sm text-center  mx-auto'>
                                      <TimestampFormatter
                                        timestamp={messages.messageCreatedAt}
                                        prevTimestamp={prevMessageTimestamp}
                                      />
                                    </div>
									<div className='flex w-full justify-end'>
										<div className='w-[70%] justify-between text-center min-w-50  borderOneNew rounded-lg sender px-10 py-3 my-3 '>
										<div className='row'><h5 className='max-sm:text-lg messageTextColor '>{messages.messageText}</h5></div>
										<div className='row text-right -my-5'><h6 className='max-sm:text-sm relativ mt-4 text-gray-100'>
											<TimestampFormatterLight
												timestamp={messages.messageCreatedAt}
												prevTimestamp={prevMessageTimestamp}
											/></h6></div>
										</div>
									</div>
                                  </>
                                )}
                                {isProfileName === messages.receiverName && (
                                  <>
                                    <div className='max-sm:text-sm text-center mx-auto'>
                                      <TimestampFormatter
                                        timestamp={messages.messageCreatedAt}
                                        prevTimestamp={prevMessageTimestamp}
                                      />
                                    </div>
									<div className='flex justify-start'>
										<div className='w-[70%]  justify-between text-center min-w-50  borderOneNew rounded-lg receiver px-10 py-3 my-3 '>			
											<div className='row'><h5 className='max-sm:text-lg text-black-500 '>{messages.messageText}</h5></div>
											<div className='row text-left -my-5'><h6 className='max-sm:text-sm relativ mt-4 text-gray-50'>
												<TimestampFormatterLight
												timestamp={messages.messageCreatedAt}
												prevTimestamp={prevMessageTimestamp}
											/></h6></div>
										</div>
									</div>
                                  </>
                                )}
                              </>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
			
          </div>
		  
        </div>
		<div className='max-md:col-sm-1 row-span-1 col-span-6 mx-6 justify-center'>
			<hr className='horizontalLine' />
		</div>
		

        {isClicked && (
          <div className="row-span-1 col-span-6 mt-5 relative">
		{/*}	
            <form onSubmit={createMessage} method="POST">
              <div className='grid grid-row-1 grid-cols-6 py-3 '>
			  <div className='max-sm:row w-full'>
			  	 <div className='max-sm:col-sm-12 flex w-full'>
					<div className="sm:flex justify-center items-center col-span-4 w-full">
					<textarea
						className='w-full bg-transparent borderOneNew resize-none text-2xl mx-auto ml-2 sm:ml-3'
						value={text}
						onChange={(e) => setText(sanitizeInputTextArea(e.target.value))}
						onClick={() => setShowEmoji(false)}
						maxrows={4}
						placeholder='Deine Nachricht'
					></textarea>
					</div>
				</div>
				<div className='max-sm:col-sm-12 flex w-full'>
						<div className='sm:flex rounded-full justify-center items-center hover:bg-yellow-400 borderOne max-sm:px-10 sm:px-[-10] mx-2' onClick={() => setShowEmoji(!showEmoji)}>
						<p className='cursor-pointer scale-150 pt-2'><ImHappy /></p>
						</div>
						{showEmoji && (
							<>
						<div className='absolute max-sm:hidden top-[-800%] right-2 z-10'>
							<Picker data={data} emojiSize={18} emojiButtonSize={26} onEmojiSelect={addEmoji} maxFrequentRows={1} perLine={10} skinTonePosition='search' previewPosition='none' />
						</div>
						<div className='absolute sm:hidden top-[-800%] right-2 z-10'>
						<Picker data={data} emojiSize={18} emojiButtonSize={22} onEmojiSelect={addEmoji} maxFrequentRows={1} perLine={7} skinTonePosition='search' previewPosition='none' />
						</div>
						</>
						)}
						<div className="flex col-span-1 w-full  mx-auto pr-2 sm:pr-3">
						<button className="btn_One w-full max-sm:px-16 max-sm:text-center sm:px-5 rounded-md titleBackgroundColor text-gray-100 ">Senden</button>
						</div>
				</div>
              </div>
			  </div>
            </form>   */}

<form onSubmit={createMessage} method="POST" className="w-full">
            <div className="flex flex-col sm:flex-row py-3 sm:space-x-2">
                {/* Textarea */}
                <div className="w-full   sm:w-4/5 flex justify-center items-center">
                    <textarea
                        className="w-full bg-transparent borderOneNew resize-none text-2xl max-sm:mx-3  sm:mx-auto sm:ml-3"
                        value={text}
                        onChange={(e) => setText(sanitizeInputTextArea(e.target.value))}
                        onClick={() => setShowEmoji(false)}
                        rows={4}
                        placeholder="Deine Nachricht"
                    ></textarea>
                </div>
                
                {/* Emoji and Send Button */}
                <div className="w-full sm:w-2/5 flex flex-col flex-row sm:justify-between sm:items-center mt-2 mx-3 sm:mt-0 space-y-2 sm:space-y-0">
				<div className='max-sm:col-sm-12 max-sm:flex'>
				    <div className='max-sm:col-sm-6 sm:mb-3 max-sm:justify-start'>
						<div className=" justify-center max-sm:item-left sm:items-center hover:bg-yellow-400 borderOne rounded-full py-1" onClick={() => setShowEmoji(!showEmoji)}>
							<p className="cursor-pointer sm:px-44 max-sm:px-20 scale-150 pt-2"><ImHappy className=''/></p>
						</div>
                    </div>
                    {showEmoji && (
                        <>
                            <div className="absolute top-[-470%] right-2 z-10 max-sm:hidden">
                                <Picker data={data} emojiSize={18} emojiButtonSize={26} onEmojiSelect={addEmoji} maxFrequentRows={1} perLine={10} skinTonePosition="search" previewPosition="none" />
                            </div>
                            <div className="absolute max-sm:top-[-335%] right-2 z-10 sm:hidden">
                                <Picker data={data} emojiSize={18} emojiButtonSize={22} onEmojiSelect={addEmoji} maxFrequentRows={1} perLine={7} skinTonePosition="search" previewPosition="none" />
                            </div>
                        </>
                    )}	
					<div className='max-sm:col-sm-6 max-sm:w-full max-sm:mr-10'>
						<button className="btn_One w-full sm:w-auto  max-sm:py-2 max-sm:mx-5 max-sm:mt-1 text-center sm:px-36 sm:py-3 rounded-md titleBackgroundColor text-gray-100">
							Senden
						</button>
					</div>
				  </div>
                </div>
            </div>
        </form>




          </div>
        )}
								

		{!isClicked && (
			<div className="row-span-1 col-span-6 mt-6">
				<div className='flex justify-center items-center scale-150'>
				<ImHappy className='h-100' />
				</div>
				<h3>Wähle ein Gespräch aus!</h3>
			</div>
			)}
		</div> 
	</div>
  </div>
 </div>


	)
																
															

}
export default Chatbox; 
export { default as Chatbox } from './Chatbox.js';