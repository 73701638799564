import './css/TestreaderView.css';
import api from '../../api/axiosConfig';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

import {LogoutRedirectCheck} from '../../tools/LogoutRedirectCheck';

import { CgChevronDown } from "react-icons/cg";
import { CgChevronRight } from "react-icons/cg";

import { BsChatDots } from "react-icons/bs";
import { GrApps } from "react-icons/gr";

function IconWithTooltip({ icon, tooltipText }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
        className=""
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.cloneElement(icon, {
          style: { fontSize: '10px' },
          title: isHovered ? tooltipText : '',
        })}
      </div>
    );
  }


const TestreaderView = ({testReaderState, setTestReaderState, baseUrl2}) => {


    const [getEntityData,setEntityData,  ] = useState([]);
    const [getSingleEntityData,setSingleEntityData, ] = useState([]);                          
    const [getSinglePrevBookData,setSinglePrevBookData,] = useState([]);

                                
    const [getRatings,setRatings, ] = useState([]);                         
    const [getSingleRating,setSingleRating, ] = useState([]); 

    const [showSearchRatingRow,setShowSearchRatingRow, ] = useState([false]); 
    const [searchInputValue,setSearchInputValue] = useState([]);

    const [getSortedScoreArray,setSortedScoreArray ] = useState([]);

    const [activeSortColumn, setActiveSortColumn] = useState('rating');

    const [sortConfig, setSortConfig] = useState('0', 'ascending');
    const [getSortedData, setSortedData] = useState([]);
    const [render, setRender] = useState(false);
    const [partRender, setPartRender] = useState(false);
    const [getRankHrefClick, setRankHrefClick] = useState(false);
    
    const [inputTrueGenres, setInputTrueGenres] = useState([]);
    const [changeGenreBanner, setChangeGenreBanner] = useState(false);

    const [error, setError] = useState(null);

       const navigate = useNavigate();      
           

       const getEntity = async () => {
        try {
          const response = await api.get("/api/testReaders", {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });
          return response.data;
        } catch (error) {
          handleError('Error fetching authors:', error);
        }
      };
      
      const getSingleEntity = async (e) => {
        e.preventDefault();
        const { target } = e;
      
        const addNewData = {
          testReaderName: target.name.value,
        };
      
        try {
          const response = await api.get(`/api/testReaders/search/${addNewData.testReaderName}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('logintoken')}` },
          });
      
          setSingleEntityData(response.data[0]);
          const singleEntity = response.data[0];
          setSinglePrevBookData(singleEntity.prevBookProjectsIds);
          showSingleRatings(singleEntity.title);
        } catch (error) {
          handleError('Error fetching single Entity:', error);
        }
      };
      
      
      const showSingleRatings = async (entityName) => {
        const newShowSearchRatingRow = true;
        setShowSearchRatingRow(newShowSearchRatingRow);
        localStorage.setItem('showSearchRatingRow', 'true');
      
        try {
          getEntityData.forEach((entity) => {
            if(entity.authorName === entityName){
              setSingleRating(entity.ratingsReceivedIds);
            }
          });

          //console.log('getSingleRating:', getSingleRating);
        } catch (error) {
          handleError('Error fetching single ratings:', error);
        }
      };

      const deleteSearchSingleEntity = () => {
        setSearchInputValue('');
      
        const newShowSearchRatingRow = false;
        setShowSearchRatingRow(newShowSearchRatingRow);
        localStorage.setItem('showSearchRatingRow', 'false');
      };
      
      const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value);
      };
      
      const fetchData = async () => {
        try {
          const Entities = await getEntity();
          setEntityData(Entities);
          //console.log('TestReaders: ',Entities )

          setTimeout(() => {                  
            sortRatingsAndGiveOrder(Entities);
            //console.log('sortRatingsAndGiveOrder: ', Entities )
            //console.log('usingSortableData: ',getSortedScoreArray )
          }, 6000);

        } catch (error) {
          handleError('Error fetching Entities:', error);
        }
      };
      
     
      const updateSearchHtml = () => {
        try {
          const storedShowSearchRatingRow = localStorage.getItem('showSearchRatingRow');
          setShowSearchRatingRow(storedShowSearchRatingRow === 'true');
        } catch (error) {
          handleError('Error updating search HTML:', error);
        }
      };

      const usingSortableData = (items) => {

        let sortableItems = [...items];
        if (sortConfig !== null) {
          sortableItems.sort((a, b) => {

       //sort genres
       if (sortConfig.key === '4') {

        // Function to count overlapping genres
        const countOverlap = (genres) => {
          if (!genres) return 0;
          const genresArray = genres.split(', ').map(genre => genre.toUpperCase());
          return genresArray.reduce((count, genre) => {
              if (inputTrueGenres.includes(genre)) {
                  return count + 1;
              }
              return count;
          }, 0);
      };

      //console.log("inputTrueGenres: ", inputTrueGenres);

      const overlapCountA = countOverlap(a[4]);  // Index 4 is where genres are stored
      const overlapCountB = countOverlap(b[4]);

     //console.log("overlapCountA: ", overlapCountA);
    // console.log("overlapCountB: ", overlapCountB);

      // Sort based on overlap count
      if (overlapCountA < overlapCountB) {
          return sortConfig.direction === 'descending' ? 1 : -1;
      }
      if (overlapCountA > overlapCountB) {
          return sortConfig.direction === 'descending' ? -1 : 1;
      }

    };

            // sort age numerical
            if (sortConfig.key === '3' || sortConfig.key === '6') {
              const A = parseInt(a[sortConfig.key], 10);
              const B = parseInt(b[sortConfig.key], 10);
              if (!A) {return sortConfig.direction === 'descending' ? -1 : 1;
              }
              if (!B) {return sortConfig.direction === 'descending' ? 1 : -1;
              } 
              if (A < B) {return sortConfig.direction === 'descending' ? -1 : 1;
              }
              if (A > B) {return sortConfig.direction === 'descending' ? 1 : -1;
              }
              return 0;
            };
              // sort alphabetically
            if (sortConfig.key === '2') {
              if (!a[sortConfig.key]) {return sortConfig.direction === 'descending' ? -1 : 1;
              }
              if (!b[sortConfig.key]) {return sortConfig.direction === 'descending' ? 1 : -1;
              } 
              return sortConfig.direction === 'descending' ? 
              b[sortConfig.key].localeCompare(a[sortConfig.key])  : a[sortConfig.key].localeCompare(b[sortConfig.key]);
            }
            // sort score and more
            if (isNaN(a[sortConfig.key])) {return sortConfig.direction === 'descending' ? -1 : 1;
            }
            if (isNaN(b[sortConfig.key])) { return sortConfig.direction === 'descending' ? 1 : -1;
            }
            if (a[sortConfig.key] < b[sortConfig.key]) {return sortConfig.direction === 'descending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {return sortConfig.direction === 'descending' ? 1 : -1;
            }
            return 0;
          });
        }
       
      setSortedData(sortableItems);
     
      
    };

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
      partRerender();
    };


    const sortRatingsAndGiveOrder = ((entityData) => {

      const newArray = [];
      try{

      if(entityData != null && entityData !== ""){

      function calculateAverageScore(userRatings) { 
        if(userRatings.length > 0) {
          const totalScore = userRatings.reduce((sum, score) => sum + score.ratingScore, 0);
          const averageScore = totalScore / userRatings.length;
          const roundedAverageRatingScore = averageScore.toFixed(1);
          //console.log(roundedAverageRatingScore);
          return roundedAverageRatingScore;
        }
    }

    entityData.forEach((entity, index) => {
        const averageScore = calculateAverageScore(entity.ratingsReceivedIds);

        const trueGenres = Object.keys(entity.genresIds[0])
        .filter(genre => entity.genresIds[0][genre] && genre !== 'id' && genre !== 'createdAt')
        .map(genre => genre.charAt(0).toUpperCase() + genre.slice(1)) 
        .join(', '); 

    		newArray[index] = [index, averageScore, entity.testReaderName, entity.age,
				 trueGenres, entity.prevBookProjectsIds.length, entity.follower ];
	});

          //console.log('newArray1Sorted:', newArray );     

         // sorting after score 
         newArray.sort((a, b) => {
          if (isNaN(a[1])) { return 1 ;}
          if (isNaN(b[1])) { return -1; }
          if (a[1] < b[1]) { return 1 ;}
          if (a[1] > b[1]) { return -1; }
          return 0;
        });    
          // giving rank
          newArray.forEach((row, index) => {
              newArray[index][0] = index+1;
          });
          // sorting after rank 
          newArray.sort((a, b) => {
            if (a[0] < b[0]) { return 1 ;}
            if (a[0] > b[0]) { return -1; }
            return 0;
          });

          usingSortableData(newArray);
          setSortedScoreArray(newArray);
          setTestReaderState(false);
          //console.log('newArraySorted:', newArray );
        }
      } catch (error) {
        handleError('Error sorting Entities and Genres:', error);
      }
  });


  const changeOtherProfileName = (otherProfileName, otherProfileType) => {
    localStorage.setItem('siteProfile', otherProfileName);
    localStorage.setItem('otherProfileType', otherProfileType);
    setRankHrefClick(true);
};


  const toggleCollapse = (column) => {   
        setActiveSortColumn(column);    
    };

    const rerender = () => {
      setRender(!render);
    }

    const partRerender = () => {
      setPartRender(!partRender);
    }

    const changeToChat = (entityName, e) => {
      e.stopPropagation();
      localStorage.setItem('chatEntityName', entityName);
    }

    const [checkedGenreOptions, setCheckedGenreOptions] = useState({
      fiction: false, nonFiction: false, mystery: false, thriller: false, romance: false,
      fantasy: false, scienceFiction: false, horror: false, historical: false, youngAdult: false, newAdult: false,
       children: false, adventure: false, biography: false, selfHelp: false, poetry: false, drama: false,
      crime: false, graphicNovels: false, shortStories: false, dystopian: false, paranormal: false, memoir: false,
       cookbooks: false, spirituality: false, travel: false, science: false, history: false
    });
    
    const handleGenreCheckboxChange = (option) => {
      setCheckedGenreOptions((prevOptions) => ({
        ...prevOptions,
        [option]: !prevOptions[option],
      }));
    };
    
    useEffect(() => {
        const getCheckedGenres = () => {
            return Object.keys(checkedGenreOptions).filter(genre => checkedGenreOptions[genre]);
        };
        setInputTrueGenres(getCheckedGenres().map(genre => genre.toUpperCase()));
    }, [checkedGenreOptions]);

   
    useEffect(() => {
      const key = "1";
      const direction = 'ascending';
      setSortConfig({ key, direction });
      fetchData();              
      setRankHrefClick(false);
      localStorage.setItem('chatEntityName', '');
    }, []);
    
    useEffect(() => {
      deleteSearchSingleEntity();
      updateSearchHtml();
      localStorage.setItem('chatEntityName', '');
    }, []);

    useEffect(() => {
      //fetchData(); 
    }, [render, getRankHrefClick]);

    useEffect(() => { 
      //fetchRatings(getAuthorData);         
       // sortRatingsAndGiveOrder(getAuthorData);
        usingSortableData(getSortedScoreArray);
    }, [partRender]);

      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

///////////////////////////////// Logout redirect //////////////////////////7

useEffect(() => {
  LogoutRedirectCheck(navigate);
}, [navigate, searchInputValue, showSearchRatingRow ]);

///////////////////////////////// Logout redirect //////////////////////////7 

  return (
    <div className="TestreaderView z-40">
    <div className="container ">
      {/*{error && <div style={{ color: 'red' }}>{error}</div>} */}

      <div className="container-sm ViewCol dashboardSearchDataCard white-glassmorphism-card">
      <div className="flex items-center mb-2 m-2">
    <h5 className="mr-4 text-xl font-bold">SUCHE</h5>
    <form onSubmit={getSingleEntity} className="flex flex-col sm:flex-row w-full gap-2">
      <input
        className="InputFieldOne flex-grow p-2 border border-gray-300 rounded"
        placeholder="Testlesername..."
        name="name"
        type="text"
        value={searchInputValue}
        onChange={handleSearchInputChange}
      />
      <div className="flex flex-col sm:flex-row gap-2">
        <button
          className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
          type="submit"
        >
          Bestätigen
        </button>
        <button
          className="sm:py-2 px-4 max-sm:text-lg bg-gray-600 text-white rounded"
          type="button"
          onClick={deleteSearchSingleEntity}
        >
          Entfernen
        </button>
      </div>
    </form>
  </div>
</div>


      { changeGenreBanner && (
                  <div className="absolute z-30 white-glassmorphism-card bg-gray-200 rounded-lg  px-5 mr-14 min-sm:right-2/3 group-hover:block mt-28 top-16">
                        <div className="flex flex-wrap gap-4">
                            {Object.entries(checkedGenreOptions).map(([genre, isChecked]) => (
                              <div key={genre} className="flex items-center mb-4 mx-3 text-3xl text-[#8b90a5] hover:text-[#babbcf]">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handleGenreCheckboxChange(genre)}
                                  id={genre}
                                  className="cursor-pointer rounded items-center  mb-6 border-gray-300"
                                />
                                <label htmlFor={genre} className="capitalize mt-3 ml-2">{genre}</label>
                              </div>
                            ))}
                          </div>
                  </div>
            )}

  
      <div className="container-sm dashboardDataCard white-glassmorphism-card">
  <div className={`overflow-auto  max-sm:pb-1  ${getSortedData.length >= 6?'':'-mb-7'}`}  style={{maxHeight: 1000}} >
    <table className="table table-bordered table-responsive table-hover mb-0">
      <thead>
        <tr>
           <th className='sticky top-0  white-glassmorphism-table' ><h6 className="sm:ml-3 mb-5 scale-75"> <IconWithTooltip icon={<BsChatDots className='scale-150'/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." /></h6></th>
          <th  className="sticky top-0  white-glassmorphism-table text-center align-middle"><div className=' sm:hidden mb-5'>Nr.</div><div className=' max-sm:hidden mb-5'>Nr.</div></th>
          <th  className="sticky top-0  white-glassmorphism-table text-center align-middle"><div className=' sm:hidden mb-5'>Rang</div><div className=' max-sm:hidden mb-5'>Rang</div></th>
          <th  onClick={() => { requestSort('1'); toggleCollapse('rating'); }} className="sticky top-0  white-glassmorphism-table text-center">
                  <div className='row text-center sm:-mb-2'><div className=' sm:hidden'>Wert</div><div className='  max-sm:hidden mb-1'>Wert</div></div>
                  <div className='row max-sm:pl-9 pl-5'>{activeSortColumn === 'rating' ? (<CgChevronDown />) : (<CgChevronRight />)}</div>
          </th>
          <th  onClick={() => {requestSort('2'); toggleCollapse('name');}} className="sticky top-0  white-glassmorphism-table text-center"><div className=' sm:hidden'>Name</div><div className=' max-sm:hidden'>Name</div>{activeSortColumn === 'name' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
          <th  onClick={() => {requestSort('3'); toggleCollapse('age');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden" >Alter{activeSortColumn === 'age' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
          <th className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden ">
                    <div className="align-items-center -mr-3">
                      <div className='flex ml-6'>Genres
                          <div className='mt-2 ml-3 mr-3 scale-100' onClick={() => {setChangeGenreBanner(!changeGenreBanner);}}><IconWithTooltip icon={<GrApps className='scale-100'/>} tooltipText="Filter nach den gewünschten Genres." /></div></div>
                            <div className="text-center max-sm:hidden ml-2" onClick={() => {requestSort('4'); toggleCollapse('genre');}}>
                              {activeSortColumn === 'genre' ? (<CgChevronDown />) : (<CgChevronRight />)}
                      </div>
                    </div>
          </th>
          <th  onClick={() => {requestSort('5'); toggleCollapse('prevProjects');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden">Projekte{activeSortColumn === 'prevProjects' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
          <th  onClick={() => {requestSort('6'); toggleCollapse('follower');}} className="sticky top-0  white-glassmorphism-table text-center max-sm:hidden"><div className=' sm:hidden'>Follower</div><div className=' max-sm:hidden'>Follower</div>{activeSortColumn === 'follower' ? (<CgChevronDown />) : (<CgChevronRight />)}</th>
	      </tr>
            </thead>
            <tbody className="table-group-divider">
  
            {showSearchRatingRow && (
            <>
	            {getSortedData.map((entity) => (
                <>
	            {entity[2] === getSingleEntityData.testReaderName && (
                <tr className="table-active">

                 {localStorage.getItem('profileName') !== entity[2] && ( 
                        <div className='p-0 m-0 mt-2'>
                        <a 
                          href="/chat" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          onClick={(e) => changeToChat(entity[2], e)}
                        >
                          <button className="bg-gray-600 text-white rounded scale-150">
                          <IconWithTooltip className="p-0 m-0" icon={<BsChatDots className="scale-75"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                          </button>
                        </a>
                        </div>
                    )}
                   {localStorage.getItem('profileName') === entity[2] && ( 
                        <div className='p-1'></div>
                                      )}

                  <td className="text-center min-sm:p-0"><h6 className="p-0 m-0">0</h6></td>  
                  <td className="col text-center  min-sm:p-0">
                     <a
                       href={`${baseUrl2}/profile?othProfileName=${entity[2]}&othProfileType=Testreader`}
                       target="_blank"
                       rel="noreferrer"
                       onClick={() => changeOtherProfileName(entity[2], 'Testreader')}
                      >
                      <h6 className="TextBold p-0 m-0">{entity[0]?'#'+ entity[0]:entity[0]===0?'#1':'-'}</h6>
                    </a>
                  </td>
                  <td className=""><h6 className='p-0 m-0'>{isNaN(entity[1])?'-':entity[1]}</h6></td>                    
                  <td className=" text-center">
                      <a
                                          href={`${baseUrl2}/profile?othProfileName=${entity[2]}&othProfileType=Testreader`}
                                          target="_blank"
                                          rel="noreferrer"
                                          onClick={() => changeOtherProfileName(entity[2], 'Testreader')}
                                          >
                        <h6 className='sm:hidden p-0 m-0'>{entity[2]?entity[2]:'-'}</h6><h6 className='max-sm:hidden'>{entity[2]?entity[2]:'-'}</h6>
                      </a>
                  </td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[3]?entity[3]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[4]?entity[4]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[5]?entity[5]:'-'}</h6></td>   
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[6]?entity[6]:'-'}</h6></td>         
                </tr>
                )}
                </>
            ))}
            </>
            )}
  
  {getSortedData.map((entity,index) => (
                <tr key={entity[0]} className="align-middle text-center justify-center items-center">
                 
                         {localStorage.getItem('profileName') !== entity[2] && ( 
                        <div className='p-0 m-0 mt-2'>
                        <a 
                          href="/chat" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          onClick={(e) => changeToChat(entity[2], e)}
                        >
                          <button className="bg-gray-600 text-white rounded scale-150">
                          <IconWithTooltip className="p-0 m-0" icon={<BsChatDots className="scale-75"/>} tooltipText="Wechsel zum Chat und schreibe direkt, falls du das Recht besitzt." />
                          </button>
                        </a>
                        </div>
                    )}
                   {localStorage.getItem('profileName') === entity[2] && ( 
                        <div className='p-1'></div>
                                      )}

		  <td className=" p-0"><h6 className="p-0 m-0">{index+1}</h6></td>
                  <td className="col text-center  min-sm:p-0">
                     <a
                       href={`${baseUrl2}/profile?othProfileName=${entity[2]}&othProfileType=Testreader`}
                       target="_blank"
                       rel="noreferrer"
                       onClick={() => changeOtherProfileName(entity[2], 'Testreader')}
                      >
                      <h6 className="TextBold p-0 m-0">{entity[0]?'#'+ entity[0]:entity[0]===0?'#1':'-'}</h6>
                    </a>
                  </td>
                  <td className="col"><h6 className='p-0 m-0'>{isNaN(entity[1])?'-':entity[1]}</h6></td>                    
                  <td className=" text-center">
                      <a
                                              href={`${baseUrl2}/profile?othProfileName=${entity[2]}&othProfileType=Testreader`}
                                              target="_blank"
                                              rel="noreferrer"
                                              onClick={() => changeOtherProfileName(entity[2], 'Testreader')}
                                              >
                        <h6 className='sm:hidden p-0 m-0'>{entity[2]?entity[2]:'-'}</h6><h6 className='max-sm:hidden p-0 m-0'>{entity[2]?entity[2]:'-'}</h6>
                      </a>
                  </td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[3]?entity[3]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[4]?entity[4]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='p-0 m-0'>{entity[5]?entity[5]:'-'}</h6></td>
                  <td className=" text-center max-sm:hidden"><h6 className='sm:hidden p-0 m-0'>{entity[6]?entity[6]:'-'}</h6><h6 className='max-sm:hidden p-0 m-0'>{entity[6]?entity[6]:'-'}</h6></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  
    
    
  );
}

export default TestreaderView; 
export { default as TestreaderView } from './TestreaderView';