import axios from 'axios';


// Axios configuration for CORS
const axiosInstance = axios.create({

    //////////////// For hosting in development  ////////////////////

 /*
  
  baseURL: 'http://localhost:8080',  // Replace with your API server URL
    headers: 
     {
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Origin': 'http://localhost:3000', // Replace with your client app origin
      
      },

    });  
   
  */  ////////////////////////////////////////////////////////////////////
   
  //////////////// For hosting in Production  ////////////////////
 
  		baseURL: 'https://api.bluumer.com/', //  bookroots.de Replace with your actual backend server URL
  		headers: {
    		'Content-Type': 'application/json',
       
  		},
	});   
   
    export default axiosInstance;


