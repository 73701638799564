import './dashboard.css';
import { Link , useNavigate} from "react-router-dom";
import api from '../api/axiosConfig';
import { useEffect, useState } from 'react';

import { ImSpinner9 } from 'react-icons/im';

//Components
import { AuthorView } from '../components/daschboardViews/AuthorView';
import { EditorView } from '../components/daschboardViews/EditorView';
import { TestreaderView } from '../components/daschboardViews/TestreaderView';
import { CoverDesignerView } from '../components/daschboardViews/CoverDesignerView';
import { PublisherView } from '../components/daschboardViews/PublisherView';
import { BookView } from '../components/daschboardViews/BookView';  
import { Footer } from '../components/navbar&Footer/Footer';
import { Navbar } from '../components/navbar&Footer/Navbar';


const Dashboard = ({baseUrl, baseUrl2}) => {

  ///////////////////////////////// Logout redirect //////////////////////////7

	const navigate = useNavigate();

  const logoutRedirectToLogin = async () => {
    const logoutRedirectText = "Please Log in"
    try{
      if(logoutRedirectText === localStorage.getItem('logintoken')){
        navigate("/");
        //console.log('Logout Redirect')
      }else{
        //console.log('Logout Redirect not Same Text')
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    logoutRedirectToLogin();
}, []);


///////////////////////////////// Logout redirect //////////////////////////7


    const [editorState, setEditorState] = useState(true);
    const [authorState, setAuthorState] = useState(true);
    const [testReaderState, setTestReaderState] = useState(true);
    const [coverDesignerState, setCoverDesignerState] = useState(true);
    const [publisherState, setPublisherState] = useState(true);
    const [bookState, setBookState] = useState(true); 

    const navigate2 = useNavigate();

    const logout = async () => {
        const logoutText = "Please Log in"
        try{
            localStorage.setItem(`logintoken`, logoutText );
            navigate2("/");
            const response = await api.get(`/logout`)

            //console.log(response.data);            
            return response.data;
                        
        } catch(err){
            console.log(err);
        }
    } 

      const profiles = ['Autoren','Bücher', 'Lektoren', 'Testleser', 'Coverdesigner', 'Verlage'];
      const [selectedProfile, setSelectedProfile] = useState(null);
    
      const handleProfileClick = (profile) => {
        setSelectedProfile(profile);
      };
    
      const getProfileStyle = (profile) => {
        return selectedProfile === profile
          ? 'bg-slate-600 text-white'  // Change color when selected
          : 'white-glassmorphism-card text-black'; // Default style
      };


      const renderSpinner = (condition) => (
        condition && (
          <div className=' blurPage z-20 fixed sm:inset-y-96 sm:h-[30%] max-sm:inset-0 max-sm:screen w-screen overflow-hidden'>
            <div className='loadingBox white-glassmorphism-loadingBox text-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight: '100px' }}>
              <div className="flex justify-content-center align-items-center w-full h-full">
                <div className='mt-10 ml-40'>
                  <ImSpinner9 className="spinning-image" size={50} color="#a7b7c8" />
                </div>
              </div>
            </div>
          </div>
        )
      );

     useEffect(() => {

          setBookState(true);
          setEditorState(true);
          setAuthorState(true);
          setTestReaderState(true);
          setCoverDesignerState(true);
          setPublisherState(true);

        }, [selectedProfile]); 



  useEffect(() => {
    setEditorState(true);
    setAuthorState(true);
    setTestReaderState(true);
    setCoverDesignerState(true);
    setPublisherState(true);
    setBookState(true); 
    
if(localStorage.getItem('currentProfileType') === 'Author'){
  setSelectedProfile('Lektoren');
}else{
  if(localStorage.getItem('currentProfileType') === 'Testreader'){
    setSelectedProfile('Bücher');
  }else{
    setSelectedProfile('Autoren');
  }
}
    
}, []);

  return (
      <div className="Dashboard z-20 skin-glassmorphism-background" style={{ minHeight: '110vh', display: 'flex', flexDirection: 'column' }}>
         
         <div className="sticky top-0 z-40 ">
                <Navbar baseUrl={baseUrl}/>
          </div>
          <div className='loggedInBackground'> 
                <h3 className='max-sm:hidden'>Dashboard</h3>

             {selectedProfile === 'Lektoren' &&  (renderSpinner(editorState))} 
             {selectedProfile === 'Autoren' &&  (renderSpinner(authorState))}  
             {selectedProfile === 'Testleser' &&  (renderSpinner(testReaderState))}  
             {selectedProfile === 'Coverdesigner' &&  (renderSpinner(coverDesignerState))}
             {selectedProfile === 'Verlage' &&  (renderSpinner(publisherState))}
             {selectedProfile === 'Bücher' &&  (renderSpinner(bookState))}  


                  <div className="mb-20 px-0 sm:mx-5 mx-0 max-sm:mx-0" style={{ flex: 1 }}>
                    <div className="row row-cols-2 sm:flex">
                    <h3 className='sm:hidden font-bold'>Dashboard</h3>

                    <div className="flex flex-wrap justify-center mt-4 space-x-2 space-y-2 sm:space-y-0">
                        
                            {profiles.map((profile) => (
                              <button
                                key={profile}
                                onClick={() => handleProfileClick(profile)}
                                className={`p-3 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 ${getProfileStyle(profile)}`}
                              >
                                {profile}
                              </button>
                            ))}


                      <div className="min-sm:col-sm-12 flex-wrap max-sm:p-0 pt-10">
                      {selectedProfile === 'Autoren' &&  (
                        <div className="col-md-12 col-lg-12 col-xl-6  my-10 px-0"> 
                          <AuthorView authorState={authorState} setAuthorState={setAuthorState} baseUrl2={baseUrl2}/>
                        </div>
                      )}
                      {selectedProfile === 'Lektoren' && (
                        <div className="col-md-12 col-lg-12 col-xl-6  my-10 px-0">
                            <EditorView editorState={editorState} setEditorState={setEditorState} baseUrl2={baseUrl2} />   
                        </div>
                      )}
                      {selectedProfile === 'Testleser' && (
                        <div className="col-md-12 col-lg-12 col-xl-6  my-10 px-0">
                          <TestreaderView testReaderState={testReaderState} setTestReaderState={setTestReaderState} baseUrl2={baseUrl2}/>  
                        </div>
                      )}
                      {selectedProfile === 'Coverdesigner' && (
                        <div className=" col-md-12 col-lg-12 col-xl-6  my-10 px-0">
                          <CoverDesignerView coverDesignerState={coverDesignerState} setCoverDesignerState={setCoverDesignerState} baseUrl2={baseUrl2} />
                        </div>
                      )}
                        {selectedProfile === 'Verlage' && (
                        <div className="col-md-12 col-lg-12 col-xl-6 my-10 px-0">
                          <PublisherView publisherState={publisherState} setPublisherState={setPublisherState} baseUrl2={baseUrl2}/> 
                        </div>
                        )}
                        {selectedProfile === 'Bücher' && (
                        <div className=" col-md-12 col-lg-12 col-xl-6 my-10 px-0">
                          <BookView bookState={bookState} setBookState={setBookState} baseUrl2={baseUrl2} />
                        </div>
                        )}
                      </div>
                    </div>  
                  </div>
          </div>
                <div className="mt-auto mb-20 ">
                      <Footer/>
                </div>
          </div>
    </div>
  );
}

export default Dashboard; 